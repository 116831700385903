import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
const Home = () => {
    const navigate = useNavigate();
   // const [userrole, setUserrole] = useState("Nicht angemeldet");
    const [areals, setAreals] = useState([]);

    useEffect(() => {
       /* const fetchUserrole = () => {
            const token = localStorage.getItem('authToken');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    setUserrole(decodedToken.userrole || "Nicht angemeldet");
                } catch (error) {
                    console.error("Fehler beim Dekodieren des Tokens:", error);
                    setUserrole("Nicht angemeldet");
                }
            }
        };*/

        const fetchAreals = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/home`);
                //setAreals(response.data);
                const arealsWithImages = await Promise.all(response.data.map(async (areal) => {
                    if (areal.imageOrder && areal.imageOrder.length > 0) {
                        try {
                            return { ...areal, imageUrl: `${process.env.REACT_APP_API_URL}/files/${areal._id}/images/${areal.imageOrder[0]}` };
                        } catch (error) {
                            console.error(`Fehler beim Laden des Bildes für Areal ${areal._id}:`, error);
                            return { ...areal, imageUrl: null };
                        }
                    }
                    return areal;
                }));
                setAreals(arealsWithImages);
            } catch (error) {
                console.error(error);
            }
        };

        //fetchUserrole();
        fetchAreals();
    }, []);

    return (
        <>
            {/* Hero-Section */}
            <div
                className="hero-section text-center text-white d-flex flex-column justify-content-center align-items-center"
                style={{
                    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('img/pexels-cafer-caner-savli-1725684886-29961083.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    width: "100%",
                    height: "50vh",
                    margin: "0",
                    padding: "20px"
                }}
            >
                <h1 className="display-4">Willkommen bei Konzeptvergabe.de!</h1>
                <p className="lead mt-3">
                    Das Portal für Kommunen und Bewerber, um Grundstücke effizient zu vermitteln und innovative Konzepte
                    umzusetzen.
                </p>
                <button
                    className="btn btn-primary btn-lg mt-3"
                    onClick={() => navigate('/register')}
                >
                    Jetzt registrieren und starten
                </button>
            </div>
            <div className="container mt-5">
                {/* Vorteile */}
                <div className="benefits-section my-5">
                    <h2 className="text-center mb-4 my-5">Warum Konzeptvergabe.de?</h2>
                    <div className="row my-5">
                        <div className="col-md-4 text-center my-3">
                            <h5>Einfache Inserate</h5>
                            <p>
                                Kommunen können Grundstücke mit wenigen Klicks einstellen und interessierte Bewerber
                                erreichen.
                            </p>
                        </div>
                        <div className="col-md-4 text-center my-3">
                            <h5>Gezielte Bewerbungen</h5>
                            <p>
                                Bewerber finden passende Grundstücke und reichen ihre Konzepte direkt über das Portal
                                ein.
                            </p>
                        </div>
                        <div className="col-md-4 text-center my-3">
                            <h5>Effizienz und Transparenz</h5>
                            <p>
                                Optimieren Sie den Prozess der Konzeptvergabe und behalten Sie den Überblick über alle
                                Bewerbungen.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Aktuelle Grundstücke */}
                <div className="areals-section my-5">
                    <h2 className="text-center mb-4">Aktuelle Grundstücke</h2>
                    <div className="row">
                        {areals.length > 0 ? (
                            areals.map((areal) => (
                                <div className="col-md-4" key={areal._id}>
                                    <div className="card mb-4">
                                        {areal.imageOrder && areal.imageOrder.length > 0 && areal.imageUrl ? (
                                            <div className="card-img-container">
                                                <img
                                                    src={`${areal.imageUrl}`}
                                                    className="card-img-top"
                                                    alt="Areal"
                                                />
                                            </div>
                                        ) : null}
                                        <div className="card-body">
                                            <h5 className="card-title">{areal.name}</h5>
                                            <p className="card-text">{areal.comment}</p>
                                            {/* Link zur Detailseite des Areals */}
                                            <a href={`/areal/${areal._id}`} className="greenlink">Mehr erfahren</a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center">Keine Inserate verfügbar.</p>
                        )}
                    </div>

                </div>
            </div>
            <div className="cta-section text-center text-light bg-dark-subtle p-5 mt-5"
                 style={{
                     backgroundImage: "url('img/pexels-cafer-caner-savli-1725684886-29961083.jpg')",
                     backgroundSize: "cover",
                     backgroundPosition: "bottom",
                     width: "100%",
                     margin: "0",
                 }}>
                <h2 className="mb-3">Sind Sie bereit, loszulegen?</h2>
                <p className="lead">
                    Bewerben Sie sich noch heute oder inserieren Sie Ihre Grundstücke und finden Sie die besten
                    Konzepte.
                </p>
                <button
                    className="btn btn-light btn-lg mt-3"
                    onClick={() => navigate('/register')}
                >
                    Jetzt registrieren
                </button>
            </div>
        </>


    );
};

export default Home;
