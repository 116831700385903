import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const RegisterUser = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Neues Passwortfeld für Bestätigung
  const [company, setCompany] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [plz, setPlz] = useState('');
  const [userrole, setUserrole] = useState('Bewerber'); // State für die Rolle
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const onChangeUsername = (e) => setUsername(e.target.value);
  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);
  const onChangeConfirmPassword = (e) => setConfirmPassword(e.target.value);
  const onChangeCompany = (e) => setCompany(e.target.value);
  const onChangeStreet = (e) => setStreet(e.target.value);
  const onChangeNumber = (e) => setNumber(e.target.value);
  const onChangeCity = (e) => setCity(e.target.value);
  const onChangePlz = (e) => setPlz(e.target.value);
  const onChangeUserrole = (e) => setUserrole(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      setErrorMessage("Passwort muss mindestens 6 Zeichen lang sein.");
      setSuccessMessage('');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Die Passwörter stimmen nicht überein.");
      setSuccessMessage('');
      return;
    }

    const newUser = {
      username,
      email,
      password,
      company,
      street,
      number,
      city,
      plz,
      userrole, // Rolle wird übertragen
      confirmed: 0 // Bestätigungsstatus bleibt standardmäßig 0
    };

    setErrorMessage('');
    setSuccessMessage('');

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/user/register`, newUser);

      // Erfolgreiche Registrierung
      if (userrole === 'Bewerber') {
        setSuccessMessage('Registrierung erfolgreich! Bitte bestätigen Sie Ihre E-Mail, um Ihr Konto zu aktivieren.');
      } else if (userrole === 'Anbieter') {
        setSuccessMessage('Registrierungsanfrage erfolgreich! Bitte bestätigen Sie Ihre E-Mail. Wir prüfen Ihr Konto und benachrichtigen Sie, sobald es verfügbar ist.');
      }

      // Felder zurücksetzen
      setUsername('');
      setEmail('');
      setPassword('');
      setConfirmPassword(''); // Auch Bestätigungsfeld zurücksetzen
      setCompany('');
      setStreet('');
      setNumber('');
      setCity('');
      setPlz('');
      setUserrole('Bewerber');

      // Weiterleitung zur Login-Seite
      setTimeout(() => navigate('/login'), 3000); // 3 Sekunden warten, um die Erfolgsnachricht anzuzeigen

    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage('Registrierung fehlgeschlagen. Bitte versuche es erneut.');
      }
      setSuccessMessage('');
    }
  };

  return (
      <div className="container main-content mt-5">
        <h2 className="text-center mb-4">Registrieren</h2>

        {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
        )}

        {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
        )}

        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Registrieren als: </label>
            <select className="form-control" value={userrole} onChange={onChangeUserrole}>
              <option value="Bewerber">Bewerber</option>
              <option value="Anbieter">Kommune</option>
            </select>
          </div>
          <br/>
          <div className="form-group">
            <label>Benutzername: </label>
            <input
                type="text"
                required
                className="form-control"
                value={username}
                onChange={onChangeUsername}
            />
          </div>
          <br/>
          <div className="form-group">
            <label>E-Mail: </label>
            <input
                type="email"
                required
                className="form-control"
                value={email}
                onChange={onChangeEmail}
            />
          </div>
          <br/>
          <div className="form-group">
            <label>Passwort: </label>
            <input
                type="password"
                required
                className="form-control"
                value={password}
                onChange={onChangePassword}
            />
          </div>
          <br/>
          <div className="form-group">
            <label>Passwort bestätigen: </label>
            <input
                type="password"
                required
                className="form-control"
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
            />
          </div>
          <br/>
          {userrole === 'Bewerber' && (
              <>
                <div className="form-group">
                  <label>Firma: </label>
                  <input
                      type="text"
                      className="form-control"
                      value={company}
                      onChange={onChangeCompany}
                  />
                </div>
                <br/>
              </>
          )}
          <div className="form-group">
            <label>Straße: </label>
            <input
                type="text"
                className="form-control"
                value={street}
                onChange={onChangeStreet}
            />
          </div>
          <br/>
          <div className="form-group">
            <label>Hausnummer: </label>
            <input
                type="text"
                className="form-control"
                value={number}
                onChange={onChangeNumber}
            />
          </div>
          <br/>
          <div className="form-group">
            <label>Ort: </label>
            <input
                type="text"
                className="form-control"
                value={city}
                onChange={onChangeCity}
            />
          </div>
          <br/>
          <div className="form-group">
            <label>PLZ: </label>
            <input
                type="number"
                className="form-control"
                value={plz}
                onChange={onChangePlz}
            />
          </div>
          <br/>
          <div className="form-group">
            <input type="submit" value="Registrierung anfragen" className="btn btn-primary"/>
          </div>
        </form>
      </div>
  );
};

export default RegisterUser;
